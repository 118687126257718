import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[(_vm.hasDuplicates)?_c(VAlert,{attrs:{"type":"warning"}},[_vm._v("There is a duplicate entry for this organisation in the subgroup")]):_vm._e(),_c('ValidationObserver',{ref:"objects",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var dirty = ref.dirty;
return _c(VForm,{},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required|min:2|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VTextField,{attrs:{"disabled":_vm.loading,"label":"Name","error-messages":errors},on:{"change":_vm.onInputChanged},model:{value:(_vm.organisationLocal.title),callback:function ($$v) {_vm.$set(_vm.organisationLocal, "title", $$v)},expression:"organisationLocal.title"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VSelect,{attrs:{"disabled":_vm.loading,"items":_vm.lists.types,"menu-props":{ maxHeight: '400' },"label":"Type","item-text":"title","item-value":"id","error-messages":errors,"readonly":!_vm.isNewRecord(),"clearable":!_vm.isNewRecord},on:{"change":_vm.onInputChanged},model:{value:(_vm.organisationLocal.organisation_types_id),callback:function ($$v) {_vm.$set(_vm.organisationLocal, "organisation_types_id", $$v)},expression:"organisationLocal.organisation_types_id"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VSelect,{attrs:{"disabled":_vm.loading,"items":_vm.lists.status,"menu-props":{ maxHeight: '400' },"item-text":"title","item-value":"id","error-messages":errors,"label":"Status","clearable":""},model:{value:(_vm.organisationLocal.organisation_statuses_id),callback:function ($$v) {_vm.$set(_vm.organisationLocal, "organisation_statuses_id", $$v)},expression:"organisationLocal.organisation_statuses_id"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Branch"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VSelect,{attrs:{"disabled":_vm.loading,"items":_vm.lists.branches,"menu-props":{ maxHeight: '400' },"item-text":"title","item-value":"id","label":"Branch","clearable":"","error-messages":errors},model:{value:(_vm.organisationLocal.branch_id),callback:function ($$v) {_vm.$set(_vm.organisationLocal, "branch_id", $$v)},expression:"organisationLocal.branch_id"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VTextField,{attrs:{"disabled":_vm.loading,"label":_vm.phoneLabel,"error-messages":errors},model:{value:(_vm.organisationLocal.phone),callback:function ($$v) {_vm.$set(_vm.organisationLocal, "phone", $$v)},expression:"organisationLocal.phone"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Mobile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VTextField,{attrs:{"disabled":_vm.loading,"label":_vm.mobileLabel,"error-messages":errors},model:{value:(_vm.organisationLocal.mobile),callback:function ($$v) {_vm.$set(_vm.organisationLocal, "mobile", $$v)},expression:"organisationLocal.mobile"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VTextField,{attrs:{"disabled":_vm.loading,"label":"Email","error-messages":errors},model:{value:(_vm.organisationLocal.email),callback:function ($$v) {_vm.$set(_vm.organisationLocal, "email", $$v)},expression:"organisationLocal.email"}})}}],null,true)})],1)],1),(_vm.isFamily)?_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Pets Indoor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VTextField,{attrs:{"disabled":_vm.loading,"label":"Pets Indoor","error-messages":errors},model:{value:(_vm.organisationLocal.pets_indoor),callback:function ($$v) {_vm.$set(_vm.organisationLocal, "pets_indoor", $$v)},expression:"organisationLocal.pets_indoor"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Pets Outdoor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VTextField,{attrs:{"disabled":_vm.loading,"label":"Pets Outdoor","error-messages":errors},model:{value:(_vm.organisationLocal.pets_outdoor),callback:function ($$v) {_vm.$set(_vm.organisationLocal, "pets_outdoor", $$v)},expression:"organisationLocal.pets_outdoor"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Family Interest"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VTextField,{attrs:{"disabled":_vm.loading,"label":"Family Interest","error-messages":errors},model:{value:(_vm.organisationLocal.family_interest),callback:function ($$v) {_vm.$set(_vm.organisationLocal, "family_interest", $$v)},expression:"organisationLocal.family_interest"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Transport and Costs"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VTextField,{attrs:{"disabled":_vm.loading,"label":"Transport and Costs","error-messages":errors},model:{value:(_vm.organisationLocal.transport),callback:function ($$v) {_vm.$set(_vm.organisationLocal, "transport", $$v)},expression:"organisationLocal.transport"}})}}],null,true)})],1)],1):_vm._e(),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Street Address"}},[_c('AddressField',{attrs:{"label":"Street Address","disabled":_vm.loading},model:{value:(_vm.organisationLocal.street_address),callback:function ($$v) {_vm.$set(_vm.organisationLocal, "street_address", $$v)},expression:"organisationLocal.street_address"}})],1)],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Postal Address"}},[_c('AddressField',{attrs:{"label":"Postal Address","disabled":_vm.loading},model:{value:(_vm.organisationLocal.postal_address),callback:function ($$v) {_vm.$set(_vm.organisationLocal, "postal_address", $$v)},expression:"organisationLocal.postal_address"}})],1)],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Postal Address same"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VSwitch,{attrs:{"disabled":_vm.loading,"label":"Postal Address same as Street Address","error-messages":errors},model:{value:(_vm.organisationLocal.same_address),callback:function ($$v) {_vm.$set(_vm.organisationLocal, "same_address", $$v)},expression:"organisationLocal.same_address"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Notes"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VTextarea,{attrs:{"label":"Notes","rows":"3","error-messages":errors,"filled":"","auto-grow":""},model:{value:(_vm.organisationLocal.notes),callback:function ($$v) {_vm.$set(_vm.organisationLocal, "notes", $$v)},expression:"organisationLocal.notes"}})}}],null,true)})],1)],1),_c(VBtn,{staticClass:"primary",attrs:{"disabled":_vm.loading || invalid || !dirty},on:{"click":_vm.saveAction}},[_vm._v("Save")])],1)}}])}),_c('ConfirmDialog',{attrs:{"show":_vm.showNavigateAwayConfirmDialog},on:{"confirm-dialog-clicked":_vm.confirmNavigate}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }