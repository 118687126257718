<template>
  <v-container>
    <ValidationObserver ref="objects" v-slot="{ invalid, dirty }">
      <v-form>
        <v-row>
          <v-col cols="12" md="6" lg="4">
            <ValidationProvider name="Name" rules="required">
              <v-text-field
                v-model="subgroupLocal.title"
                :disabled="loading"
                label="Subgroup Name"
                :error-messages="errors"
                slot-scope="{ errors }"
                readonly
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <ValidationProvider name="Status" rules="required">
              <v-select
                v-model="subgroupLocal.subgroup_status_id"
                :disabled="loading"
                :items="lists.status"
                item-text="title"
                item-value="id"
                :error-messages="errors"
                slot-scope="{ errors }"
                :menu-props="{ maxHeight: '400' }"
                label="Status"
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <ValidationProvider name="Type" rules="required">
              <v-select
                v-model="subgroupLocal.group_types_id"
                :disabled="loading"
                :items="lists.types"
                :menu-props="{ maxHeight: '400' }"
                label="Subgroup Type"
                item-text="title"
                item-value="id"
                :error-messages="errors"
                slot-scope="{ errors }"
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <ValidationProvider name="Homestay Start Date" rules="date">
              <DateInput
                v-model="subgroupLocal.homestay_start_date"
                label="Homestay Start Date"
                :disabled="loading"
                :error-messages="errors"
                slot-scope="{ errors }"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <ValidationProvider name="Homestay End Date" rules="date">
              <DateInput
                v-model="subgroupLocal.homestay_end_date"
                label="Homestay End Date"
                :disabled="loading"
                :error-messages="errors"
                slot-scope="{ errors }"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <ValidationProvider name="Interaction School 1">
              <AutoComplete
                v-model="subgroupLocal.interaction_school_1"
                :disabled="loading"
                label="Interaction School 1"
                :error-messages="errors"
                slot-scope="{ errors }"
                search-service="organisation"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <ValidationProvider name="Interaction School 2">
              <AutoComplete
                v-model="subgroupLocal.interaction_school_2"
                :disabled="loading"
                label="Interaction School 2"
                :error-messages="errors"
                slot-scope="{ errors }"
                search-service="organisation"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <ValidationProvider name="ESL Teacher">
              <AutoComplete
                v-model="subgroupLocal.esl_teacher"
                :disabled="loading"
                label="ESL Teacher"
                :error-messages="errors"
                slot-scope="{ errors }"
                search-service="staff"
                sort-by="surname"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <ValidationProvider name="1st Group Coordinator">
              <AutoComplete
                v-model="subgroupLocal.group_coordinator_1"
                :disabled="loading"
                label="1st Group Coordinator"
                :error-messages="errors"
                slot-scope="{ errors }"
                search-service="staff"
                sort-by="surname"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <ValidationProvider name="2nd Group Coordinator">
              <AutoComplete
                v-model="subgroupLocal.group_coordinator_2"
                :disabled="loading"
                label="2nd Group Coordinator"
                :error-messages="errors"
                slot-scope="{ errors }"
                search-service="staff"
                sort-by="surname"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <ValidationProvider name="3rd Group Coordinator">
              <AutoComplete
                v-model="subgroupLocal.group_coordinator_3"
                :disabled="loading"
                label="Assist Group Coordinator"
                :error-messages="errors"
                slot-scope="{ errors }"
                search-service="staff"
                sort-by="surname"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <ValidationProvider name="Regional Manager">
              <AutoComplete
                v-model="subgroupLocal.regional_manager"
                :disabled="loading"
                label="Regional Manager"
                :error-messages="errors"
                slot-scope="{ errors }"
                search-service="staff"
                sort-by="surname"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <ValidationProvider name="Study Center">
              <AutoComplete
                v-model="subgroupLocal.study_center"
                :disabled="loading"
                label="Study Center"
                :error-messages="errors"
                slot-scope="{ errors }"
                search-service="organisation"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <ValidationProvider name="Age level">
              <v-select
                v-model="subgroupLocal.age_level_id"
                :disabled="loading"
                :items="lists.ages"
                :menu-props="{ maxHeight: '400' }"
                label="Age Levels"
                item-text="title"
                item-value="id"
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <ValidationProvider name="Doctor">
              <AutoComplete
                v-model="subgroupLocal.doctor"
                :disabled="loading"
                label="Doctor"
                :error-messages="errors"
                slot-scope="{ errors }"
                search-service="organisation"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <ValidationProvider name="Dentist">
              <AutoComplete
                v-model="subgroupLocal.dentist"
                :disabled="loading"
                label="Dentist"
                :error-messages="errors"
                slot-scope="{ errors }"
                search-service="organisation"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <ValidationProvider name="Proposed Teacher Escort">
              <v-text-field
                v-model="subgroupLocal.proposed_teachers"
                :disabled="loading"
                label="Proposed Teacher Escort"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <ValidationProvider name="Proposed Students">
              <v-text-field
                v-model="subgroupLocal.proposed_students"
                :disabled="loading"
                label="Proposed Students"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <ValidationProvider name="Actual Teacher Escort">
              <v-text-field
                v-model="subgroupLocal.actual_teachers"
                disabled
                label="Actual Teacher Escort"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <ValidationProvider name="Actual Students Recruited">
              <v-text-field
                v-model="subgroupLocal.actual_students"
                label="Actual Students Recruited"
                disabled
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <ValidationProvider name="Number of HF Recruited">
              <v-text-field v-model="subgroupLocal.hf_recruited" disabled label="Number of HF Recruited"></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" lg="4" v-if="subgroupLocal.subgroup_status_id == 3">
            <ValidationProvider name="Cancellation Date" rules="required|date">
              <DateInput
                v-model="subgroupLocal.cancellation_date"
                label="Cancellation Date"
                :disabled="loading"
                :error-messages="errors"
                slot-scope="{ errors }"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <ValidationProvider name="ESL Required">
              <v-switch v-model="subgroupLocal.esl_required" label="ESL Required"></v-switch>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <ValidationProvider name="Involuntary Start">
              <v-switch v-model="subgroupLocal.involuntary_start" label="Involuntary Start"></v-switch>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <ValidationProvider name="Involuntary End">
              <v-switch v-model="subgroupLocal.involuntary_end" label="Involuntary End"></v-switch>
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Notes">
              <v-textarea v-model="subgroupLocal.notes" label="Notes" rows="3" filled auto-grow></v-textarea>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-btn @click="saveAction" :disabled="loading || invalid || !dirty" class="primary">Save</v-btn>
      </v-form>
    </ValidationObserver>
    <ConfirmDialog :show="showNavigateAwayConfirmDialog" @confirm-dialog-clicked="confirmNavigate" />
  </v-container>
</template>

<script>
import loadDash from 'lodash';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { mapGetters } from 'vuex';
import store from '@/store/index';
import { date } from '@/utils/helpers';
import DateInput from '@/views/DateInput';
import AutoComplete from '@/components/AutoComplete';
import ConfirmDialog from '@/views/dialogs/ConfirmDialog';

extend('required', required);
extend('date', date);

export default {
  name: 'SubgroupDetailForm',
  beforeRouteLeave(to, from, next) {
    if (this.isDirty()) {
      this.next = next;
      this.showNavigateAwayConfirmDialog = true;
    } else {
      next();
    }
  },
  components: {
    AutoComplete,
    ConfirmDialog,
    DateInput,
    ValidationProvider,
    ValidationObserver
  },
  computed: {
    /**
     * The mapGetters helper simply maps store getters to local computed properties:
     */
    ...mapGetters('subgroup', ['loading', 'subgroup', 'error', 'success', 'meta']),
    /*
     * We retrive the meta data for each requests. This allows us to update the lists
     * if they have changed in the application.
     */
    lists: function () {
      if (this.meta && this.meta.lists) {
        return this.meta.lists;
      }
      return {
        status: [],
        types: [],
        ages: []
      };
    },
    /**
     * To work with v-model and vuex, we only want to keep
     * a local copy until the user is ready to save.
     */
    subgroupLocal: function () {
      const subgroup = loadDash.cloneDeep(this.subgroup);

      // Get the parent group id
      if (this.$route.query.group_id && this.subgroup.id == 0) {
        subgroup.group_id = parseInt(this.$route.query.group_id, 10);
      }

      return subgroup;
    }
  },
  methods: {
    /**
     * Save method for the form
     */
    async saveAction() {
      const result = await this.$refs.objects.validate();
      if (result) {
        await store.dispatch('subgroup/save', {
          id: this.subgroupLocal.id,
          subgroup: this.subgroupLocal
        });
      }
      this.resetValidation();
    },
    /**
     * Check if this a new record
     */
    isNewRecord() {
      return this.$route.params.id == 'new' ? true : false;
    },
    /**
     * Check if the form has changed after loading
     */
    isDirty() {
      return this.$refs.objects._data.flags.dirty;
    },
    /**
     * Reset the validation, this will make sure the dirty flags get set to
     * false after save action.
     */
    resetValidation() {
      requestAnimationFrame(() => {
        this.$refs.objects.reset();
      });
    },
    confirmNavigate: function (navigateAway) {
      this.showNavigateAwayConfirmDialog = false;
      if (navigateAway) {
        this.next();
      }
    }
  },
  data: () => ({
    showNavigateAwayConfirmDialog: false
  })
};
</script>
